<template>
  <div>
    <vx-card>
      <div class="grid grid-rows-1">
        <div class="grid grid-cols-2">
          <div class="float-left text-sm">
            <label class="font-bold">{{ $t('valor') }}:</label>
            <label>
              ({{ formatScore(maxScore)}})
            </label>
          </div>
        </div>
      </div>
      <div>
        <multiple-choice-show
          :item="completeQuestion"
          v-if="questionType === 'multiple_choice'"
          :key="key"
        ></multiple-choice-show>

        <check-box-show
          :item="completeQuestion"
          v-if="questionType === 'check_box'"
          :key="key"
        ></check-box-show>

        <simple-text-show
          :item="completeQuestion"
          v-if="questionType === 'simple_text'"
          :key="key"
        ></simple-text-show>

        <gap-show
          :item="completeQuestion"
          v-if="questionType === 'gap'"
          :key="key"
        ></gap-show>
      </div>
    </vx-card>
  </div>
</template>

<script>
import MultipleChoiceShow from '../../answers/questions/show/MultipleChoiceShow.vue'
import CheckBoxShow from '../../answers/questions/show/CheckBoxShow.vue'
import SimpleTextShow from '../../answers/questions/show/SimpleTextShow.vue'
import GapShow from '../../answers/questions/show/GapShow.vue'

export default {
  components: { MultipleChoiceShow, CheckBoxShow, SimpleTextShow, GapShow },
  data: () => ({
    key: 0
  }),
  props: {
    //Question in DTO or Entity
    question: {
      type: Object,
      default: null
    }
  },
  computed: {
    maxScore() {
      const score = _.get(this.question, 'data.score')
      return score
    },
    questionType() {
      const type = _.get(this.question, 'type')
      return type
    },
    completeQuestion() {
      return this.normalizeAnswer()
    }
  },
  watch: {
    question() {
      this.key++
    }
  },
  methods: {
    normalizeAnswer() {
      //Implementado para receber o DTO de question/answer
      //Implementar para receber a entity, se for o caso
      const q = {
        data : this.question.data,
        answer : this.question.answer.data.answer
      }
      return q
    }
  }
}
</script>

<style>

</style>
