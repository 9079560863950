<template>
  <div>
    <vx-card class="mb-4">
      <div>
        <vx-card :title="$t('respostas')" class="mb-4">
          <div class="vx-row w-full">
            <div class="vx-col sm:w-6/12 w-6/12">
              <b>{{$t('Questionnaire')}}</b>
            </div>
            <div class="vx-col sm:w-6/12 w-6/12">
              <b>{{$t('questionnaire_corrector._status')}}</b>
            </div>
          </div>
          <div class="vx-row w-full">
            <div class="vx-col sm:w-6/12 w-4/12">{{questionnaire.name}}</div>
            <div class="vx-col sm:w-6/12 w-4/12"><span :class="`${cssStatus}`">{{entityModel.status.value}}</span></div>
          </div>
          <!-- -->
          <div class="vx-row w-full mt-5">
            <div class="vx-col sm:w-12/12 w-12/12">
               <vs-collapse accordion type="border" v-if="!allCriteria">
                <vs-collapse-item v-for="(question, index) in questions" :key="`question_${question.id}`" :ref="`collapse_${question.id}`">
                  <div slot="header">
                    <div class="grid grid-cols-2 mb-2">
                      <div class="flex justify-start">
                        <div><b>{{ $t('questao-this-index', [index+1]) }}</b></div>
                      </div>
                      <div class="flex justify-end">
                        <b>{{ $t('pontuacao-maxima') }} : {{formatScore(question.data.score)}}</b>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full">
                       <div class="editor-content ck-content content-break-word ml-3 mr-4">{{shortTitle(question.data.title, 200)}}</div>
                      </div>
                      <div class="flex">
                        <div class="grid content-center self-center  pr-6 pb-5">
                            <vs-button
                                type="flat"
                                color="warning"
                                icon="visibility"
                                @click="showCompleteQuestionModal(question)"
                                v-tooltip.top="{
                                  content: 'Visualizar questão completa',
                                  delay: { show: 1000, hide: 100 }
                                }"
                              ></vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                  <div class="flex mb-2">
                    <div class="w-full">
                      <vs-divider class="mt-0" position="left" background="success">{{ $t('resposta_uppercase') }}</vs-divider>
                    </div>
                    <div class="flex shrink w-64 justify-end">
                      <b>{{$t('pontuacao-total')}}: {{formatScore(question.answer.score)}}</b>
                    </div>
                  </div>
                  <div class="pl-5">
                    <div v-html-safe="formatTextAreaToHtml(question.answer.data.answer)" v-if="!isQuestionAnswerEmpty(question.answer.data.answer)"></div>
                    <div v-if="isQuestionAnswerEmpty(question.answer.data.answer)">{{$t('nao-respondida')}}</div>
                    <vs-divider position="left" class="mt-2 mb-2"></vs-divider>
                    <vx-card :title="$t('questionnaire_corrector.evaluation.title')" class="mb-4 mt-4" v-if="!isQuestionAnswerEmpty(question.answer.data.answer)">
                      <div class="">
                        <div v-for="(criteria, crIndex) in question.criteria" :key="`criteria_${index}_${crIndex}`">
                          <div class="flex flex-col md:flex-row gap-x-4">
                            <div class="flex-grow">
                              <vx-input-group>
                                <label for="" class="vs-input--label">{{formatIndex(crIndex, criteria.weight)}}</label>
                                <vs-input
                                  class="w-full"
                                  disabled
                                  :value="criteria.title"
                                  />
                                  <template slot="append">
                                      <div class="append-text btn-addon flex items-end">
                                          <span class="input-group-text includeIcon includeIconOnly vs-component">
                                            {{formatScore(criteria.score)}}
                                          </span>
                                        </div>
                                    </template>
                                </vx-input-group>
                            </div>
                          </div>
                          <div class="flex flex-col md:flex-row gap-x-4 pt-2 pb-3">
                            <div class="flex-grow">
                              <div class="pl-1 editor-content ck-content content-break-word">
                                <div v-html-safe="criteria.description"></div>
                              </div>
                            </div>
                            <div class="flex pt-3 pb-4 items-center justify-end" v-if="enableCorrection">
                              <div class="inline-flex" role="group">
                                <vs-button
                                    class="-ml-0.5 inline-block rounded-l rounded-r-none border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    color="primary"
                                    type="border"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('0%')"
                                    @click="setScore(question, criteria, question.data.score, 0)">
                                    0
                                  </vs-button>
                                  <vs-button
                                    color="primary"
                                    type="border"
                                    class="-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('25%')"
                                    @click="setScore(question, criteria, question.data.score, 25)">
                                    25
                                  </vs-button>
                                  <vs-button
                                    color="primary"
                                    type="border"
                                    class="-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('50%')"
                                    @click="setScore(question, criteria, question.data.score, 50)">
                                    50
                                  </vs-button>
                                  <vs-button
                                    color="primary"
                                    type="border"
                                    class="-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('75%')"
                                    @click="setScore(question, criteria, question.data.score, 75)">
                                    75
                                  </vs-button>
                                  <vs-button
                                    color="primary"
                                    type="border"
                                    class="-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('100%')"
                                    @click="setScore(question, criteria, question.data.score, 100)">
                                    100
                                  </vs-button>
                                  <vs-button
                                    disabled
                                    color="primary"
                                    type="border"
                                    class="inline-block rounded-r rounded-l-none border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    :title="$t('%')">
                                    %
                                  </vs-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </vx-card>
                  </div>
                </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
        </vx-card>
      </div>


      <!-- row in action-->
      <div class="vx-row mb-2 mt-8 mr-3">
        <div class="w-full flex gap-2 justify-end">
          <vs-button
             v-if="enableFinalizeCorrection"
            v-permission="'questionnaire_correctors.teacher.evaluate'"
            @click="finalizeCorrection">
            {{ $t('action.finalize_correction') }}
          </vs-button>
          <vs-button
            v-if="enableOpenCorrection"

            @click="openCorrection">
            {{ $t('action.reopen_correction') }}
          </vs-button>
          <vs-button
            type="border"
            @click="gotBack">
            {{ $t('common.back') }}
          </vs-button>
        </div>
      </div> <!-- End row in action -->
    </vx-card><!-- End vx-card -->


    <vs-popup title :active.sync="showCompleteQuestion" >
      <question-anwer-show
        :question="completeQuestion"
      />
    </vs-popup>

  </div><!-- End div template-->
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

import CorrectionCriteria from './CorrectionCriteria.vue'

import QuestionAnwerShow from './../questionnaires/elements/questionnaire_answers/QuestionAnwerShow.vue'

export default {
  directives: {

  },
  components: {
    CorrectionCriteria,
    QuestionAnwerShow
  },
  props: {
    questionnaireCorrectorId: {
      default: null,
      type: Number
    },
    answerId: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    questionnaire: {
      id: '',
      name : ''
    },
    entityModel: {
      status: {
        key: null,
        value: ''
      }
    },
    questions: [],
    showCompleteQuestion: false,
    completeQuestion: null,
    service: null
  }),
  computed: {
    enableCorrection() {
      return (this.entityModel.status.key === 'CP' || this.entityModel.status.key === 'IC')
    },
    enableFinalizeCorrection() {
      return (this.entityModel
              && this.entityModel.status !== null
               && this.entityModel.status.key !== 'SR'
               && this.entityModel.status.key !== 'CP')

    },
    enableOpenCorrection() {
      return (this.entityModel
              && this.entityModel.status !== null
               && this.entityModel.status.key === 'SR')
    },
    cssStatus() {
      const status = _.get(this.entityModel.status, 'key')
      switch (status) {
      case (null):
        return 'text-danger'
      case ('CP'):
        return 'text-danger'
      case ('IC'):
        return 'text-warning'
      case ('SR'):
        return 'text-primary'
      default:
        return ''
      }
    },
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)

  },
  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$vs.loading()
      const promisses = []

      //Questions
      promisses.push(
        this.service.findQuestionAnswers(this.questionnaireCorrectorId, this.answerId).then(
          response => {
            this.entityModel.status.key = _.get(response.correction.status, 'key')
            this.entityModel.status.value = _.get(response.correction.status, 'value')
            this.questionnaire = _.get(response.correction, 'questionnaire')
            this.questions = response.questions
          })
      )
      //
      Promise.all(promisses).then(() => {
        this.$vs.loading.close()
        console.log('all load...')
      })
    },
    saveCorrection() {

      const questionsCriteria = []

      //Get arrays from questions
      this.questions.forEach((question, index) => {
        const questionModel = {
          id: question.id,
          criteria: []
        }

        questionsCriteria.push(questionModel)

        if (question.criteria) {
          question.criteria.forEach((criteria, cIndex) => {
            const criteriaModel = {
              id: criteria.id,
              score: criteria.score ? criteria.score : 0,
              evaluated: criteria.evaluated ? criteria.evaluated : false
            }
            questionModel.criteria.push(criteriaModel)

          })
        }
      })//End forEach questions

      const modelData = {
        questionnaire_corrector_id: this.questionnaireCorrectorId,
        answer_id: this.answerId,
        data: {
          questions: questionsCriteria
        }
      }
      this.service.saveCorrection(this.questionnaireCorrectorId, this.answerId, modelData).then(
        response => {
          console.log('Salved score')
          this.entityModel.status.key = response.status
          this.entityModel.status.value = response.status_str
        },
        error => {
          //this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    finalizeCorrection() {
      this.$vs.loading()
      this.service.finalizeCorrection(this.questionnaireCorrectorId, this.answerId).then(
        response => {
          this.entityModel.status.key = response.correction.status
          this.entityModel.status.value = response.correction.status_str

          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          console.log('Finalize correction')
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    openCorrection() {
      this.$vs.loading()
      this.service.openCorrection(this.questionnaireCorrectorId, this.answerId).then(
        response => {
          this.entityModel.status.key = response.status
          this.entityModel.status.value = response.status_str

          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          console.log('Reopen correction')
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    setScore(question, criteria, scoreMax, value) {
      const weight = criteria.weight
      const weightPerc = weight / 100
      const scoreMaxWeight = parseFloat(scoreMax) * weightPerc
      const score = scoreMaxWeight * (value / 100)

      criteria.score = score
      criteria.evaluated = true

      this.setTotalScore(question)

      this.saveCorrection()
    },
    setTotalScore(question) {
      let total = 0

      question.criteria.forEach((criteria, cIndex) => {
        total = criteria.score ? total + criteria.score : total
      })

      question.answer.score = total
    },
    formatIndex(index, weight) {
      index++
      let label = `${this.$t('questionnaire_corrector.criteria') } ${ index}`
      label = `${label  } | ${this.$t('questionnaire_corrector.evaluation.weight') } : ${ weight  }%`
      return label
    },
    isQuestionAnswerEmpty(answer) {
      return (answer === undefined || answer === '' || answer === null)
    },
    showCompleteQuestionModal(question) {
      this.completeQuestion = question
      this.showCompleteQuestion = true
    },
    gotBack() {
      this.$router.push('/questionnaire_correctors/corrections')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss">
  .vs-collapse-item--header{
    font-size: 0.9rem !important;
  }
  .vs-collapse-item--content{
    height: auto !important;
  }
  .vs-input--label{
    font-size: 0.9em !important;
  }
  .__grid-columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
</style>
