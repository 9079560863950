var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c(
          "div",
          [
            _c(
              "vx-card",
              { staticClass: "mb-4", attrs: { title: _vm.$t("respostas") } },
              [
                _c("div", { staticClass: "vx-row w-full" }, [
                  _c("div", { staticClass: "vx-col sm:w-6/12 w-6/12" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Questionnaire")))]),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-6/12 w-6/12" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("questionnaire_corrector._status"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row w-full" }, [
                  _c("div", { staticClass: "vx-col sm:w-6/12 w-4/12" }, [
                    _vm._v(_vm._s(_vm.questionnaire.name)),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-6/12 w-4/12" }, [
                    _c("span", { class: "" + _vm.cssStatus }, [
                      _vm._v(_vm._s(_vm.entityModel.status.value)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row w-full mt-5" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-12/12 w-12/12" },
                    [
                      !_vm.allCriteria
                        ? _c(
                            "vs-collapse",
                            { attrs: { accordion: "", type: "border" } },
                            _vm._l(_vm.questions, function (question, index) {
                              return _c(
                                "vs-collapse-item",
                                {
                                  key: "question_" + question.id,
                                  ref: "collapse_" + question.id,
                                  refInFor: true,
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid grid-cols-2 mb-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex justify-start",
                                            },
                                            [
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "questao-this-index",
                                                        [index + 1]
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex justify-end" },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("pontuacao-maxima")
                                                  ) +
                                                    " : " +
                                                    _vm._s(
                                                      _vm.formatScore(
                                                        question.data.score
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "flex" }, [
                                        _c("div", { staticClass: "w-full" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "editor-content ck-content content-break-word ml-3 mr-4",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.shortTitle(
                                                    question.data.title,
                                                    200
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "grid content-center self-center  pr-6 pb-5",
                                            },
                                            [
                                              _c("vs-button", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip.top",
                                                    value: {
                                                      content:
                                                        "Visualizar questão completa",
                                                      delay: {
                                                        show: 1000,
                                                        hide: 100,
                                                      },
                                                    },
                                                    expression:
                                                      "{\n                                content: 'Visualizar questão completa',\n                                delay: { show: 1000, hide: 100 }\n                              }",
                                                    modifiers: { top: true },
                                                  },
                                                ],
                                                attrs: {
                                                  type: "flat",
                                                  color: "warning",
                                                  icon: "visibility",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showCompleteQuestionModal(
                                                      question
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("div", { staticClass: "flex mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "w-full" },
                                        [
                                          _c(
                                            "vs-divider",
                                            {
                                              staticClass: "mt-0",
                                              attrs: {
                                                position: "left",
                                                background: "success",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("resposta_uppercase")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex shrink w-64 justify-end",
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("pontuacao-total")
                                              ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.formatScore(
                                                    question.answer.score
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "pl-5" },
                                      [
                                        !_vm.isQuestionAnswerEmpty(
                                          question.answer.data.answer
                                        )
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "html-safe",
                                                  rawName: "v-html-safe",
                                                  value:
                                                    _vm.formatTextAreaToHtml(
                                                      question.answer.data
                                                        .answer
                                                    ),
                                                  expression:
                                                    "formatTextAreaToHtml(question.answer.data.answer)",
                                                },
                                              ],
                                            })
                                          : _vm._e(),
                                        _vm.isQuestionAnswerEmpty(
                                          question.answer.data.answer
                                        )
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(_vm.$t("nao-respondida"))
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("vs-divider", {
                                          staticClass: "mt-2 mb-2",
                                          attrs: { position: "left" },
                                        }),
                                        !_vm.isQuestionAnswerEmpty(
                                          question.answer.data.answer
                                        )
                                          ? _c(
                                              "vx-card",
                                              {
                                                staticClass: "mb-4 mt-4",
                                                attrs: {
                                                  title: _vm.$t(
                                                    "questionnaire_corrector.evaluation.title"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {},
                                                  _vm._l(
                                                    question.criteria,
                                                    function (
                                                      criteria,
                                                      crIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            "criteria_" +
                                                            index +
                                                            "_" +
                                                            crIndex,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-col md:flex-row gap-x-4",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex-grow",
                                                                },
                                                                [
                                                                  _c(
                                                                    "vx-input-group",
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "vs-input--label",
                                                                          attrs:
                                                                            {
                                                                              for: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatIndex(
                                                                                crIndex,
                                                                                criteria.weight
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "vs-input",
                                                                        {
                                                                          staticClass:
                                                                            "w-full",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                "",
                                                                              value:
                                                                                criteria.title,
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "append",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "append-text btn-addon flex items-end",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "input-group-text includeIcon includeIconOnly vs-component",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.formatScore(
                                                                                          criteria.score
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-col md:flex-row gap-x-4 pt-2 pb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex-grow",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pl-1 editor-content ck-content content-break-word",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "html-safe",
                                                                                rawName:
                                                                                  "v-html-safe",
                                                                                value:
                                                                                  criteria.description,
                                                                                expression:
                                                                                  "criteria.description",
                                                                              },
                                                                            ],
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.enableCorrection
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex pt-3 pb-4 items-center justify-end",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "inline-flex",
                                                                          attrs:
                                                                            {
                                                                              role: "group",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "-ml-0.5 inline-block rounded-l rounded-r-none border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "0%"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setScore(
                                                                                      question,
                                                                                      criteria,
                                                                                      question
                                                                                        .data
                                                                                        .score,
                                                                                      0
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 0 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "25%"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setScore(
                                                                                      question,
                                                                                      criteria,
                                                                                      question
                                                                                        .data
                                                                                        .score,
                                                                                      25
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 25 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "50%"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setScore(
                                                                                      question,
                                                                                      criteria,
                                                                                      question
                                                                                        .data
                                                                                        .score,
                                                                                      50
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 50 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "75%"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setScore(
                                                                                      question,
                                                                                      criteria,
                                                                                      question
                                                                                        .data
                                                                                        .score,
                                                                                      75
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 75 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "-ml-0.5 rounded-none inline-block  border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "100%"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setScore(
                                                                                      question,
                                                                                      criteria,
                                                                                      question
                                                                                        .data
                                                                                        .score,
                                                                                      100
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 100 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "vs-button",
                                                                            {
                                                                              staticClass:
                                                                                "inline-block rounded-r rounded-l-none border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                  type: "border",
                                                                                  "data-te-ripple-init":
                                                                                    "",
                                                                                  "data-te-ripple-color":
                                                                                    "light",
                                                                                  title:
                                                                                    _vm.$t(
                                                                                      "%"
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " % "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-row mb-2 mt-8 mr-3" }, [
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end" },
            [
              _vm.enableFinalizeCorrection
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "questionnaire_correctors.teacher.evaluate",
                          expression:
                            "'questionnaire_correctors.teacher.evaluate'",
                        },
                      ],
                      on: { click: _vm.finalizeCorrection },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("action.finalize_correction")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.enableOpenCorrection
                ? _c("vs-button", { on: { click: _vm.openCorrection } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("action.reopen_correction")) + " "
                    ),
                  ])
                : _vm._e(),
              _c(
                "vs-button",
                { attrs: { type: "border" }, on: { click: _vm.gotBack } },
                [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "vs-popup",
        {
          attrs: { title: "", active: _vm.showCompleteQuestion },
          on: {
            "update:active": function ($event) {
              _vm.showCompleteQuestion = $event
            },
          },
        },
        [
          _c("question-anwer-show", {
            attrs: { question: _vm.completeQuestion },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }