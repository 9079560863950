var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", [
        _c("div", { staticClass: "grid grid-rows-1" }, [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "float-left text-sm" }, [
              _c("label", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("valor")) + ":"),
              ]),
              _c("label", [
                _vm._v(" (" + _vm._s(_vm.formatScore(_vm.maxScore)) + ") "),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          [
            _vm.questionType === "multiple_choice"
              ? _c("multiple-choice-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.questionType === "check_box"
              ? _c("check-box-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.questionType === "simple_text"
              ? _c("simple-text-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.questionType === "gap"
              ? _c("gap-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }